<template>
    <div>
      <center><img :src="'/images/proximamente.jpg'" class="img2" /></center>
      <p class="text">¡Disponible muy pronto!</p>
    </div>
  </template>
  
  <style land="scss" scoped>
  @import "../styles/notfound.scss";
  </style>
  